import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const FanMarketing = ({ location }) => {
  const title =
    "プロが教えるTwitterインスタントウィン・キャンペーンのやり方。売上アップに効果的なのは「ファンマーケティング」"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="プロが教えるTwitterインスタントウィンキャンペーンのやり方 | 売上アップにはファンマーケティング"
          pagedesc="Twitterでキャンペーンを効果的に開催するコツは、ファンマーケティング。開催回数・運用方法の観点から、効果が出ない・売上に繋がらない等課題への解決法を提案します。LTV向上へ効果的なファンマーケティングも解説しています。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/fan-marketing.jpg"
          pagepath="/blog/fan-marketing"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は10分で読むことができます
            </div>
            <h1>
              プロが教えるTwitterインスタントウィン・キャンペーンのやり方。売上アップに効果的なのは「ファンマーケティング」
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2021年9月20日</time>
            <figure className="blog__thumb">
              <img
                layout="fullWidth"
                placeholder="tracedSVG"
                alt="複数人が集まってノートパソコンで作業をしている様子"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/fan-marketing.jpg"
              />
            </figure>
            <div>
              <p>こんにちは、PARKLoT マーケティング担当の島田旬子です！</p>
              <p>
                Twitterでのキャンペーン開催に関して、お客様から多く寄せられる課題は次の2つです。
                <br />
                ・キャンペーンを開催しても効果が出ない。
                <br />
                ・フォロワーが増えたのに売上につながらない。
              </p>
              <p>
                <strong>
                  Twitterキャンペーンを開催すると、多くのケースでフォロワーは増えます。
                  <br />
                  しかし、それを売上（効果）につなげるためには注意するべきポイントがあります。
                </strong>
              </p>
              <p>
                <strong>それは「キャンペーンの回数」です。</strong>
              </p>
              <p>
                思うように結果を出せず悩んでいるケースで多いのは、キャンペーンを1回かぎりの単発で開催している場合なのです。
              </p>
              <p>正直にお伝えすると、これは絶対にオススメしません！</p>
              <p>
                <strong>
                  最短でも3か月以上の期間で、プレゼント内容を変えながら継続的に実施することを強く推奨します。
                </strong>
              </p>
              <p>
                その理由をTwitterキャンペーンの運用方法と合わせて詳しく説明します。
              </p>
              <p>この記事は次のような人にオススメです！</p>
              <ul className="blog__border-list">
                <li>Twitterキャンペーンの効果が出ない</li>
                <li>Twitterキャンペーンでリード（見込み客）を獲得できない</li>
                <li>飲食店のTwitterを運用している</li>
              </ul>
              <p>
                今回の記事では、Twitterキャンペーンを効果的に実施するコツを「開催回数」と「運用方法」にフォーカスして解説します。
              </p>
              <p>
                こちらの
                <a href="#id8">「開催回数」</a>と<a href="#id4">「運用方法」</a>
                を押せば、すぐに読みたい箇所にジャンプできます。
              </p>
              <p>
                この記事でさらなるリード（見込み客）獲得につなげていただければと思います。
              </p>
            </div>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    Twitterキャンペーンの特徴
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        ・店舗・企業とのコミュニケーションが生まれる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        ・広告が苦手な人にもリーチできる
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    Twitterキャンペーンの効果的な運用方法
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        ・ファンマーケティングを活用する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        ・2割のファンが全体の売り上げを作る
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        ・ファンが知りたい情報を発信する
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    Twitterキャンペーンの効果的な開催回数
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        ・一回限りのキャンペーンはNG
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        ・継続的なキャンペーンのメリット
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        ・最適なキャンペーン回数は？
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        ・キャンペーンの頻度はどうすれば？
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id13" className="toc__link">
                    Twitterキャンペーンの費用
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">Twitterキャンペーンの特徴</h2>
              <p>
                まず最初にマーケティング初心者の方のために、Twitterで開催するキャンペーンについて説明します。
              </p>
              <p>
                <strong>
                  Twitterでのキャンペーン開催は、一般ユーザーの参加条件がフォロー＆リツイートなので多くの人に情報拡散できるのが特徴です。
                </strong>
              </p>
              <p>
                Twitterにおけるキャンペーンの開催には、主に「インスタントウィン」というキャンペーンツールが使われます。
              </p>
              <p>
                インスタントウィンは、参加者がフォローやリツイートなどの条件を満たすことで、リアルタイムで当選結果を自動表示することができます。
              </p>
              <p>
                <strong>
                  参加者がその場ですぐに賞品やクーポンを獲得できるため、キャンペーン参加のハードルを下げられます。
                </strong>
              </p>

              <h3 id="id2">店舗・企業とのコミュニケーションが生まれる</h3>
              <p>
                Twitterのキャンペーンで賞品やクーポンが当選した時には、参加者が喜びのあまり「やったー！」「●●が当たった！！」などのツイートをすることが多々あります。
              </p>
              <p>
                <strong>
                  そのツイートに対して主催者が「おめでとうございます」などとリプライできるため、参加者と自然なコミュニケーションが生まれます。
                </strong>
              </p>
              <p>
                また、抽選で外れたときには「来週もやるのでぜひ参加してくださいね」と参加者をフォローすることもできます。落選したことによるマイナスイメージの拡散を防ぐだけでなく、ユーザーの満足度アップにも繋がります。
              </p>
              <p>
                それらのやりとりは他の人もタイムライン上で目にするので、店舗・企業の好感度を高め、新たな参加者やファンを生み出すことも期待できます。
              </p>

              <h3 id="id3">広告が苦手な人にもリーチできる</h3>
              <p>
                様々なメディアで広告が表示されるなか、広告自体に嫌悪感を持つ人は少なくありません。ステルス・マーケティング（ステマ）など、記事と広告が曖昧な状態へ不信感を持つ人もいます。
              </p>
              <p>
                飲食店のクチコミなど、ユーザーの生の声が重要視されるなかで、Twitterにおけるユーザーのつぶやき（ツイート）は貴重な情報源になっています。
              </p>
              <p>
                <strong>
                  フォローしている知人からの「買って良かった」「大好きなお店」などの言葉が気になって、実際の来店や商品購入に結びつくことが多々あります。
                </strong>
              </p>
              <p>
                <strong>
                  プラスの口コミを数多く生み出すTwitterキャンペーンは、広告が苦手な人にもリーチできる有効な手段だといえるでしょう。
                </strong>
              </p>
            </div>
            <div>
              <h2 id="id4">Twitterキャンペーンの効果的な運用方法</h2>
              <p>
                次にTwitterキャンペーンの効果的な運用方法について説明します。
              </p>
              <p>
                Twitterキャンペーンで顧客を獲得する大前提として、日本には少子高齢化による人口減少の問題があります。
              </p>
              <p>
                <strong>
                  新規顧客を増やすことだけに注力していると、潜在的な顧客が減り続けている日本において、継続的な売上拡大は見込めません。
                </strong>
              </p>
              <p>
                <strong>
                  それよりも、既存の顧客に長くたくさん使ってもらうことを優先し、LTV※
                  を高めることに努めた方が断然コスパが良いのです。
                </strong>
              </p>
              <small>
                ※LTV……Life Time
                Value（ライフタイムバリュー）の略称。日本語では「顧客生涯価値」と訳され、ある顧客が一生のうちに、自社製品・サービスをどれだけ購入、利用してくれるのかを示した利益の合計値。
              </small>

              <h3 id="id5">ファンマーケティングを活用する　</h3>
              <p>
                <strong>
                  LTVを高めるために有効なのが、ファンマーケティングという考え方です。
                </strong>
              </p>
              <p>
                ファンマーケティングは、市場全体に情報を発信してリードを獲得する一般的なマーケティング手法とは異なります。対象範囲を絞って、自社サービスのファンに対して密接にコミュニケーションをとっていく手法です。
              </p>
              <p>
                <strong>
                  ファンからの信頼、共感、愛着を深めながら、継続的な売上成長を目指していきます。Twitterにおけるファンマーケティングのメリットは、大きく分類すると次の3つです。
                </strong>
              </p>
              <p>
                <strong>①安定した売上を実現</strong>
                <br />
                継続的なファン（リピーター）の支えを基盤にして経営やマーケティングを考えていくので、安定した売上を実現できます。
              </p>
              <p>
                <strong>②店舗・企業への愛着が生まれる</strong>
                <br />
                Twitter上では面識のないファン同士も、店舗・企業をフォローすることで緩やかに繋がっています。そのため、ファン同士のツイートにより愛着や共感が自然と醸成されていきます。
              </p>
              <p>
                <strong>③新規顧客の増加</strong>
                <br />
                Twitter上でファンは、気に入った自社商品のことをツイートすることがあります。それらがタイムライン上に流れたとき、友人や知人のツイートであれば思わず目が止まることも。
              </p>
              <p>
                関係性が近い人からの情報は、広告よりもはるかに信用されやすいので、新規顧客の獲得に繋がります。
              </p>
              <p>
                <strong>
                  自分が使っている商品を人に勧めるのは、他者から否定される可能性もあるため、勇気がいる行為です。ユーザーが自社商品についてツイートしてくれるのは、ファンマーケティングだからこそのメリットだといえるでしょう。
                </strong>
              </p>
              <h3 id="id6">2割のファンが全体の売り上げを作る</h3>
              <p>
                <strong>
                  多くの業種では、ファンの上位20%（優良顧客）が売上の80%を支えているといわれています。
                </strong>
                正確にこの割合になることは珍しいですが、この「2：8の法則」とも呼ばれるパレートの法則は、ビジネスの世界では盛んに活用されています。
              </p>
              <p>
                <strong>
                  ファンマーケティングでは、この20%のファンに訴求することが重要です。商品のファンであるため熱量が高く、積極的にツイートしてくれるからです。
                </strong>
              </p>
              <p>
                キャンペーン開催の際には、この2割のファンが喜ぶような商品をプレゼントに設定するようにしましょう。
                <strong>
                  例えば、誰でも喜びそうな商品券ではなく、自社商品のクーポンor引換券を配布することで店舗・企業への愛着を深めることができます。
                </strong>
              </p>

              <h3 id="id7">ファンが知りたい情報を発信する</h3>
              <p>
                <strong>
                  Twitterではキャンペーン開催のお知らせツイート以外に、ファンが知りたい情報も発信するようにしましょう。
                </strong>
              </p>
              <p>
                例えば、新商品や人気商品についての情報など。それまでの商品とどんな違いがあるのか、開発者にしか分からない細部のことまで丁寧に紹介することが大切です。
              </p>
              <p>
                開発秘話などストーリー性のある情報もファンには喜ばれます。時には質問が寄せられることもありますが、不都合なことであっても誠実に答えるようにしましょう。正直に答えることで人間味が伝わり、信頼を得ることができます。
              </p>
              <p>
                <strong>
                  また、商品について詳しくツイートするときには、キャンペーンと連動して情報発信した方がいいでしょう。キャンペーンの当選賞品orクーポン該当商品について、詳しく紹介するのがオススメです。
                </strong>
              </p>
            </div>
            <div>
              <h2 id="id8">Twitterキャンペーンの効果的な開催期間と頻度</h2>
              <p>
                次に効果的なTwitterキャンペーンの開催期間について説明します。
              </p>
              <h3 id="id9">一回限りのキャンペーンはNG</h3>
              <p>
                <strong>
                  一回だけで終わる単発のキャンペーンでは、店舗・企業あるいは商品のファンを増やすことができず、すぐに忘れ去られてしまいます。どれだけ魅力的な賞品をプレゼントしても、フォロワーの増加は一時的なものでしかありません。
                </strong>
              </p>
              <p>
                キャンペーン終了後には、フォロワー解除あるいはブロックされるケースもあります。特に注意が必要なのは、ミュートされているケース。フォロワー解除やブロックは、主催者側（店舗・企業）で確認できますが、ミュートされたことは分かりません。
              </p>
              <p>
                その結果、フォロワーは減っていないのに情報が届いていない状態（ミュートでタイムライン上に表示されない）になってしまいます。
              </p>
              <h3 id="id10">継続的なキャンペーンのメリット</h3>
              <p>
                <strong>
                  店舗・企業への愛着を深めるためには、継続的なキャンペーン開催が必要です。
                </strong>
              </p>
              <p>
                一回限りのキャンペーンでは、そこでコミュニケーションが途切れる可能性がありますが、継続的に開催する場合は、次回のキャンペーンを告知してファンの期待感を高めることができます。
              </p>
              <p>
                <strong>
                  参加者は次回のキャンペーン情報が気になるため、フォロワー解除やブロック（ミュート）の行動をとる可能性が低くなります。
                </strong>
              </p>
              <p>
                また、継続的にキャンペーンを開催し、自社商品をプレゼントに設定することで、フォロワーに自社商品を何度も見てもらうことができます。Twitterユーザーの中には、頻繁にタイムラインをチェックする人が多いからです。
              </p>
              <p>
                自社商品の画像がフォロワーの潜在意識にすり込まれることで「アレに買い替えようかな」「暑いからアレが食べたいな」など、無意識的な購買に繋げることができます。
              </p>
              <h3 id="id11">最適なキャンペーン期間は？</h3>
              <p>
                Twitterキャンペーンは、プレゼント内容を変えながら最低でも３ヶ月以上続けることを強くオススメします。
              </p>
              <p>
                <strong>
                  PARKLoTを導入していただいたお客様の事例でも、3か月以上のご利用になると飛躍的なフォロワー増加が見られます。
                </strong>
              </p>
              <p>
                <strong>
                  理想をいえば、年間を通じてキャンペーン開催することをオススメします。毎日開催していることで、Twitterを開いた時にいつでもキャンペーンに参加することができ、接触効果が高まるからです。
                </strong>
              </p>
              <p>
                初めのうちは興味のない商品であっても、何度も目にするうちに次第に好感を持つようになります。これは「ザイオンス効果」という行動心理学の理論を応用しており、マーケティングの世界では幅広く活用されています。
              </p>
              <h3 id="id12">キャンペーン頻度はどうすれば？</h3>
              <p>
                Twitterでキャンペーンを継続的に開催する場合、実施内容（プレゼント）を1〜２週間ごとに変えるのが一般的です。「今週はこの商品のクーポンをプレゼントします」など、賞品の内容を少しずつ変えることで、参加者は飽きることなくキャンペーンを楽しめます。
              </p>
              <p>
                <strong>
                  配布する賞品やクーポンを変更する頻度は、顧客の利用頻度に合わせることが理想です。例えば、飲食店の場合には次のような更新頻度が考えられます。
                </strong>
              </p>
              <p>
                <strong>
                  ・ランチで使いやすいお店 → 週に１回の来店を想定 →
                  【週替わりのキャンペーン】
                  <br />
                  ・ディナーで利用されるお店 → 月に１回の来店を想定 →
                  【月替わりのキャンペーン】
                </strong>
              </p>
              <p>
                業種や業態によって異なりますが、顧客単価が高い場合は更新頻度を毎月１回にするなど、利用者の購入頻度に合わせて開催数を調整するようにしましょう。
              </p>
            </div>
            <div>
              <h2 id="id13">Twitterキャンペーンの費用</h2>
              <p>最後にTwitterキャンペーンの費用について説明します。</p>
              <p>
                PARKLoTを開発するにあたって、他社のTwitterキャンペーンツールを色々と調査しましたが、利用者（店舗・企業）の目線で考えると「かなり高額」という印象でした。費用の相場は以下の通りです。
              </p>
              <ul className="blog__border-list">
                <p>
                  【他社の料金設定】
                  <br />
                  ・初期費用　　5〜50万円
                  <br />
                  ・月額費用　　20〜50万円
                  <br />
                  ※月額費用がなく毎回3〜5万円の費用が発生するツールもありました。
                </p>
              </ul>
              <p>
                開催する店舗・企業は、この他にもデジタルギフト手数料、LPデザイン費、バナーデザイン費などを支払う必要があるため、継続的に利用するにはハードルが高いという印象でした。
              </p>
              <p>
                そこでPARKLoTでは、主催者側の負担を少なく、継続的にキャンペーンを開催できるように、使用料金をかなり抑えて設定しています。
              </p>
              <ul className="blog__border-list">
                <p>
                  【PARKLoTの料金設定】
                  <br />
                  ・初期費用　　無料
                  <br />
                  ・月額費用　　5万円〜
                  <br />
                  <small>
                    3ヶ月契約：60,000円 /月 (税込66,000円)、6ヶ月契約：55,000円
                    /月 (税込60,500円)、
                    <br />
                    年間契約 ：50,000円 /月 (税込55,000円)　　※
                    3ヶ月以上の契約が必要です。
                  </small>
                </p>
              </ul>
              <p>
                このリーズナブルな料金設定を見て、思わず不安になった人もいらっしゃるのではないでしょうか。実は、この料金設定には明確な理由があります。
              </p>
              <p>
                <strong>
                  それは、PARKLoTが少数精鋭のチームで自社開発されているから。大企業（他社）の開発現場では、大規模なチームが組まれ、一つのプロセスで10人以上の決済が発生するなど、様々な人的コストが発生します。
                </strong>
              </p>
              <p>
                <strong>
                  一方のPARKLoTは、最少人数の優れたエンジニアが責任を持って完成させているので、不要な人的コストを全てカットできました。つまり、人件費を思いっきり削減できているので、この破格の利用料を実現できているわけです。
                </strong>
              </p>
              <p>
                PARKLoTの機能についての説明は<a href="/">料金</a>
                を確認していただければと思いますが、シンプルで使いやすい機能がそろっています。
              </p>
              <p>Twitterでキャンペーンの運用を始めるには、十分な機能です。</p>
              <ul className="blog__border-list">
                <li>
                  インスタントウィン……結果はDMで通知。当落結果はTwitter認証不要で確認できる！
                </li>
                <li>
                  クーポン配布……「消込型」「時限式」「期間中無制限」3種類のクーポンを配布。
                </li>
                <li>
                  リツイートユーザー収集……リツイートユーザーを全件取得。さまざまな分析に活用可能。
                </li>
                <li>
                  クイズ・診断……自社サイトへの誘導に効果バツグン。さらにワクワク感を演出できる。
                </li>
              </ul>
              <p>
                キャンペーン開始まで最短2分です。
                <br />
                Twitterキャンペーンを始めたい方は、PARKLoTをぜひご検討ください！
              </p>
            </div>
            <Link to={`/`}>
              <div className="blog__text-center">
                <img
                  layout="constrained"
                  placeholder="tracedSVG"
                  alt="PARKLoT(パークロット)"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/brand/blue.png"
                  maxwidth={300}
                />
              </div>
            </Link>
            <p>
              <a
                href="https://hub.park-lot.com/document-download"
                target="_blank"
                rel="noreferrer"
              >
                お得にTwitterキャンペーンをはじめる！資料ダウンロードはこちら
              </a>
            </p>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="junko" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default FanMarketing
